'use client';

import { FocusEvent, FormEvent, useEffect, useState } from 'react';

import { useRouter, useSearchParams } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';

import AppCounter from '@/components/search/AppCounter';
import AppCalendar from '@/components/layout/AppCalendar';
import AppSearchOptionButton from '@/components/search/AppSearchOptionButton';
import AppSearchOptionWrapper from '@/components/search/AppSearchOptionWrapper';
import { goToSearchKeepingState } from 'hooks/querySearch';
import {
  useCheckIn,
  useCheckOut,
  useGuestAdults,
  useGuestChildren,
  useGuestInfants,
  useGuestBikes,
  useDestinationName,
  useOriginName,
  useGuestYouths,
} from 'hooks/useQuerySearch';
import { ESearchMenu } from 'interfaces';
import { EHeaderOptions, IExploreNearby } from 'typings';
import { formatCheckDate, formatGuests } from 'utils';

import AppAutocomplete from '../layout/AppAutocomplete';
import AppNearby from '../layout/AppNearby';
import AppNearbyList from '../layout/AppNearbyList';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ArrowRightLeft } from 'lucide-react';
import {
  allowBrowsingDestinations,
  top5DestinationsFrom,
  top5OriginStations,
} from 'config/topOriginStations';

interface IAppSearchBarProps {
  searchMenu: ESearchMenu | null;
  setSearchMenu: (searchmenu: ESearchMenu | null) => void;
  exploreNearby: IExploreNearby[];
  menu: EHeaderOptions | null;
  isActiveHeader: boolean;
  closeSearchOnSubmit: boolean;
  setIsActiveSearch: (isActiveSearch: boolean) => void;
}

const AppSearchBar = ({
  menu,
  searchMenu,
  setSearchMenu,
  exploreNearby,
  isActiveHeader,
  setIsActiveSearch,
  closeSearchOnSubmit = true,
}: IAppSearchBarProps) => {
  const locale = useLocale();
  const router = useRouter();
  const searchParams = useSearchParams();
  const t = useTranslations('Header.search');

  const [originName, setOriginName] = useOriginName();
  const [typedOrigin, setTypedOrigin] = useState<string>(originName);

  const [destinationName, setDestinationName] = useDestinationName();
  const [typedDestination, setTypedDestination] = useState<string>(destinationName);

  const [checkIn, setCheckIn] = useCheckIn();
  const [, setCheckOut] = useCheckOut();

  const [guestAdults, setGuestAdults] = useGuestAdults();
  const [guestYouths, setGuestYouths] = useGuestYouths();
  const [guestChildren, setGuestChildren] = useGuestChildren();
  const [guestInfants, setGuestInfants] = useGuestInfants();
  const [guestBikes, setGuestBikes] = useGuestBikes();

  const [enter, setEnter] = useState(false);

  // handler
  const handleOnBlur = (event?: FocusEvent<HTMLElement>) => {
    const { relatedTarget } = event || {};
    if (!relatedTarget) {
      setSearchMenu(null);
      return;
    }
    const relatedTargetClassList = Array.from((relatedTarget as Element)?.classList);
    const result = relatedTargetClassList.some((className) => {
      const prefix = ['rdr', 'btn'];
      if (prefix.includes(className.slice(0, 3))) {
        return true;
      }
    });
    if (!result) {
      setSearchMenu(null);
    }
  };

  const resetDate = async () => {
    await setCheckIn(null);
    await setCheckOut(null);
    handleOnBlur();
  };

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!originName) {
      setSearchMenu(ESearchMenu.ORIGIN);
      return;
    }
    if (!guestAdults && !guestChildren && !guestYouths) {
      setSearchMenu(ESearchMenu.GUESTS);
      return;
    }
    closeSearchOnSubmit && setIsActiveSearch(false);
    setSearchMenu(null);

    await goToSearchKeepingState(router, searchParams);
  };

  const dateRangeStyle =
    'left-4 right-4 searchbar:left-auto searchbar:right-1/2 searchbar:translate-x-1/2 searchbar:w-[850px]';

  //if the enter key is pressed and menu is check In, then handle on sumbit
  useEffect(() => {
    if (searchMenu === ESearchMenu.CHECK_IN) {
      setSearchMenu(ESearchMenu.GUESTS);
    }
    if (searchMenu === ESearchMenu.DESTINATION) {
      setSearchMenu(ESearchMenu.CHECK_IN);
    } else if (searchMenu === ESearchMenu.GUESTS) {
      document.getElementById('button-search')?.click();
    }
  }, [enter]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEnter(!enter);
      e.preventDefault();
    }
  };

  useEffect(() => {
    const input = document.getElementById('text-box-origin') as HTMLInputElement;

    input.addEventListener('keydown', handleKeyDown);
    return () => {
      input.removeEventListener('keydown', handleKeyDown);
    };
  }, [originName, searchMenu]);

  // Ensure the search bar is updated when the origin or destination changes
  useEffect(() => {
    setTypedOrigin(originName);
  }, [originName]);
  useEffect(() => {
    setTypedDestination(destinationName);
  }, [destinationName]);

  return (
    <>
      <div className={`${isActiveHeader ? 'visible' : 'hidden'} px-4`}>
        <div
          className={`${
            !isActiveHeader
              ? 'z-[100] translate-y-[-75px] scale-50 transform opacity-0'
              : ''
          }  mx-auto mt-10 hidden max-w-[850px] rounded-full border border-gray-200 bg-transparent shadow-md backdrop-blur-lg duration-300 md:flex`}
        >
          <form
            action="/"
            id="form-theForm"
            className={`${!!searchMenu ? 'bg-gray-100' : 'bg-white'} ${
              menu === EHeaderOptions.MULTIDESTINATION
                ? 'grid-cols-2'
                : 'grid-cols-[auto,auto]'
            } grid flex-grow rounded-full`}
            onSubmit={handleOnSubmit}
          >
            <span className="flex items-center">
              <AppSearchOptionButton
                separator={!destinationName}
                relative
                type="inputText"
                id="origin"
                title={t('from.title')}
                placeholder={t('from.placeholder')}
                active={searchMenu === ESearchMenu.ORIGIN}
                value={typedOrigin}
                onChange={({ target }) => setTypedOrigin(target.value)}
                onFocus={() => {
                  setSearchMenu(ESearchMenu.ORIGIN);
                }}
                onBlur={handleOnBlur}
                onClear={async () => {
                  await Promise.all([
                    setTypedOrigin(''),
                    setOriginName(null),
                    setDestinationName(null),
                  ]);
                }}
              >
                <AppSearchOptionWrapper className="left-0 w-[300px] !px-4">
                  <div
                    role="listbox"
                    aria-label="Suggestions de recherche"
                    id="/homes-1-listbox"
                    className="flex flex-col justify-start overflow-auto "
                  >
                    {typedOrigin ? (
                      <>
                        <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                          Results
                        </h3>
                        <AppAutocomplete
                          onEnter={enter}
                          locationName={typedOrigin}
                          changeOrigin={true}
                          onClick={() => {
                            setSearchMenu(ESearchMenu.CHECK_IN);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                          {t('popular')}
                        </h3>
                        <AppNearbyList
                          changeOrigin={true}
                          numberOfItems={4}
                          include={top5OriginStations}
                          onClick={() => {
                            setSearchMenu(ESearchMenu.CHECK_IN);
                          }}
                          exploreNearby={exploreNearby}
                        />
                      </>
                    )}
                  </div>
                </AppSearchOptionWrapper>
              </AppSearchOptionButton>
              <span
                role="button"
                tabIndex={0}
                className={`${
                  (!destinationName || destinationName === 'Anywhere') && 'hidden'
                } text-gray-400 z-10 px-1 flex`}
                onClick={() => {
                  setOriginName(destinationName);
                  setDestinationName(originName);
                }}
              >
                <ArrowRightLeft />
              </span>

              {menu === EHeaderOptions.STATION_TO_STATION && (
                <>
                  {/* destination */}
                  <AppSearchOptionButton
                    separator
                    relative
                    type="inputText"
                    id="destination"
                    title={t('to.title')}
                    placeholder={t('to.placeholder')}
                    active={searchMenu === ESearchMenu.DESTINATION}
                    value={typedDestination}
                    onChange={({ target }) => setTypedDestination(target.value)}
                    onFocus={() => {
                      setSearchMenu(ESearchMenu.DESTINATION);
                    }}
                    onBlur={handleOnBlur}
                    onClear={() => {
                      setTypedDestination('');
                    }}
                  >
                    <AppSearchOptionWrapper className="left-0 w-[300px] !px-4">
                      <div
                        role="listbox"
                        aria-label="Suggestions de recherche"
                        id="/homes-2-listbox"
                        className="flex flex-col justify-start overflow-auto"
                      >
                        {typedDestination ? (
                          <AppAutocomplete
                            locationName={typedDestination}
                            onEnter={enter}
                            onClick={() => {
                              setSearchMenu(ESearchMenu.CHECK_IN);
                            }}
                          />
                        ) : (
                          <>
                            {allowBrowsingDestinations(originName) && (
                              <>
                                <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                                  {t('to.inspiration.headline')}
                                </h3>
                                <AppNearby
                                  data={{
                                    name: t('to.inspiration.title'),
                                    stationName: t('to.inspiration.stationName'),
                                    img: '/assets/maps/mapIcon.png',
                                    country: t('to.inspiration.subtitle'),
                                  }}
                                  isSmall
                                  outline
                                  disabled
                                  onClick={async () => {
                                    setSearchMenu(ESearchMenu.CHECK_IN);
                                    await setDestinationName('Anywhere');
                                  }}
                                />
                              </>
                            )}

                            <h3 className="Autocomplete-heading m-0 pt-2 text-sm font-light text-muted-foreground">
                              {t('popular')}
                            </h3>
                            <AppNearbyList
                              numberOfItems={4}
                              exclude={originName}
                              onClick={() => {
                                setSearchMenu(ESearchMenu.CHECK_IN);
                              }}
                              exploreNearby={exploreNearby}
                              include={top5DestinationsFrom(originName)}
                              isSmall
                            />
                          </>
                        )}
                      </div>
                    </AppSearchOptionWrapper>
                  </AppSearchOptionButton>
                  {/* check in */}
                  <AppSearchOptionButton
                    separator
                    id="check-in"
                    title={t('checkIn.title')}
                    placeholder={t('checkIn.placeholder')}
                    active={searchMenu === ESearchMenu.CHECK_IN}
                    value={formatCheckDate(checkIn, locale)}
                    onFocus={() => setSearchMenu(ESearchMenu.CHECK_IN)}
                    onBlur={handleOnBlur}
                    onClear={resetDate}
                  >
                    {/* date picker */}
                    <AppSearchOptionWrapper className={dateRangeStyle}>
                      {searchMenu === ESearchMenu.CHECK_IN && <AppCalendar />}
                    </AppSearchOptionWrapper>
                  </AppSearchOptionButton>
                </>
              )}
            </span>
            {/* guests */}
            <AppSearchOptionButton
              relative
              withSearch
              id="guests"
              title={t('travelers.title', {
                count: guestAdults + guestYouths + guestChildren + guestInfants,
              })}
              placeholder={t('travelers.placeholder')}
              active={searchMenu === ESearchMenu.GUESTS}
              value={formatGuests(
                {
                  guestAdults,
                  guestYouths,
                  guestChildren,
                  guestInfants,
                  guestBikes,
                },
                t
              )}
              onFocus={() => setSearchMenu(ESearchMenu.GUESTS)}
              onBlur={handleOnBlur}
              onClear={async () => {
                await Promise.all([
                  setGuestAdults(null),
                  setGuestYouths(null),
                  setGuestChildren(null),
                  setGuestInfants(null),
                  setGuestBikes(null),
                ]);

                handleOnBlur();
              }}
              isSearch={
                !!searchMenu && guestAdults + guestYouths >= 1 && originName !== null
              }
              onSearch={() => setSearchMenu(ESearchMenu.ORIGIN)}
            >
              <AppSearchOptionWrapper className="right-0 w-96">
                <div>
                  <div className="flex border-b border-gray-200 border-opacity-70 py-4">
                    <div className="flex-grow">
                      <h2 className="font-medium">{t('travelers.adults.plural')}</h2>
                      <p className="text-sm leading-4 text-gray-400">
                        {t('travelers.adults.subtitle')}
                      </p>
                    </div>
                    <AppCounter
                      value={guestAdults}
                      setValue={setGuestAdults}
                      maxValue={16}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex border-b border-gray-200 border-opacity-70 py-4">
                    <div className="flex-grow">
                      <h2 className="font-medium">{t('travelers.youths.plural')}</h2>
                      <p className="text-sm leading-4 text-gray-400">
                        {t('travelers.youths.subtitle')}
                      </p>
                    </div>
                    <AppCounter
                      value={guestYouths}
                      setValue={setGuestYouths}
                      maxValue={16}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex border-b border-gray-200 border-opacity-70 py-4">
                    <div className="flex-grow">
                      <h2 className="font-medium">{t('travelers.children.plural')}</h2>
                      <p className="text-sm leading-4 text-gray-400">
                        {t('travelers.children.subtitle')}
                      </p>
                    </div>
                    <AppCounter
                      value={guestChildren}
                      setValue={setGuestChildren}
                      maxValue={5}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex border-b border-gray-200 border-opacity-70 py-4">
                    <div className="flex-grow">
                      <h2 className="font-medium">{t('travelers.infants.plural')}</h2>
                      <p className="text-sm leading-4 text-gray-400">
                        {t('travelers.infants.subtitle')}
                      </p>
                    </div>
                    <AppCounter
                      value={guestInfants}
                      setValue={setGuestInfants}
                      maxValue={5}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex py-4">
                    <div className="flex-grow">
                      <h2 className="font-medium">{t('travelers.bikes.plural')}</h2>
                      <p className="text-sm leading-4 text-gray-400">
                        {t('travelers.bikes.subtitle')}
                      </p>
                    </div>
                    <AppCounter
                      value={guestBikes}
                      setValue={setGuestBikes}
                      maxValue={2 * guestAdults}
                    />
                  </div>
                </div>
              </AppSearchOptionWrapper>
            </AppSearchOptionButton>
          </form>
        </div>
      </div>
    </>
  );
};

export default AppSearchBar;
