'use client';

import { useState } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import { useRouter, useSearchParams } from 'next/navigation';

import AppCounter from '@/components/search/AppCounter';
import AppCalendar from '@/components/layout/AppCalendar';
import AppSearchOptionWrapperMobile from '@/components/search/AppSearchOptionWrapperMobile';
import { goToSearchKeepingState } from 'hooks/querySearch';
import {
  useOriginName,
  useCheckIn,
  useCheckOut,
  useGuestAdults,
  useGuestChildren,
  useGuestInfants,
  useGuestBikes,
} from 'hooks/useQuerySearch';
import { IGuestCount } from 'typings';
import { useTranslations } from 'next-intl';

interface IAppSearchOptionMobileProps {
  active: boolean;
  onClose: () => void;
}

const AppSearchOptionMobile = ({ active, onClose }: IAppSearchOptionMobileProps) => {
  const t = useTranslations('Header.search');

  const SearchStep: Record<string, string> = {
    DateStep: t('checkIn.funTitle'),
    PassengersStep: t('travelers.funTitle'),
  };
  const [step, setStep] = useState<string>(SearchStep.DateStep);
  const router = useRouter();
  const searchParams = useSearchParams();

  const [originName] = useOriginName();
  const [checkIn, setCheckIn] = useCheckIn();
  const [, setCheckOut] = useCheckOut();

  const [guestAdults, setGuestAdults] = useGuestAdults();
  const [guestChildren, setGuestChildren] = useGuestChildren();
  const [guestInfants, setGuestInfants] = useGuestInfants();
  const [guestBikes, setGuestBikes] = useGuestBikes();

  return (
    <div
      className={`${
        active ? 'visible opacity-100' : 'hidden opacity-0'
      } fixed inset-0 z-50 flex flex-col items-end justify-end bg-gradient-to-r from-orange to-orange duration-200`}
    >
      <div
        className={`${
          active ? 'translate-y-0' : 'hidden translate-y-full'
        } fixed bottom-0 left-0 right-0 w-full duration-700`}
      >
        <h2 className="w-full px-4 py-3 text-2xl font-medium text-white">{step}</h2>
        <AppSearchOptionWrapperMobile
          haveNavigation={true}
          title={originName || 'Origin'}
          handleOnBack={async () => {
            if (step === SearchStep.DateStep) {
              await Promise.all([setCheckOut(null), setCheckIn(null)]);
              onClose();
            }
            if (step === SearchStep.PassengersStep) {
              await Promise.all([
                setGuestChildren(null),
                setGuestInfants(null),
                setGuestBikes(null),
              ]);
            }
            setStep(SearchStep.DateStep);
          }}
        >
          {step === SearchStep.DateStep && (
            <DatePicker
              handleOnNext={() => setStep(SearchStep.PassengersStep)}
              checkIn={checkIn}
              setCheckIn={setCheckIn}
              setCheckOut={setCheckOut}
              onClose={onClose}
            />
          )}
          {step === SearchStep.PassengersStep && (
            <GuestPicker
              handleOnNext={async () => {
                await goToSearchKeepingState(router, searchParams);
                setStep(SearchStep.DateStep);
                onClose();
              }}
              guests={{ guestAdults, guestChildren, guestInfants, guestBikes }}
              setterGuests={{
                setGuestAdults,
                setGuestChildren,
                setGuestInfants,
                setGuestBikes,
              }}
              onClose={onClose}
            />
          )}
        </AppSearchOptionWrapperMobile>
      </div>
    </div>
  );
};

const DatePicker = ({ handleOnNext, checkIn, setCheckIn, setCheckOut, onClose }) => {
  const t = useTranslations('Header.search');
  return (
    <>
      <div className="-mb-2 overflow-y-auto text-center">
        <AppCalendar months={1} />
      </div>
      <div className="fixed bottom-0 left-0 right-0 grid grid-cols-2 items-center gap-x-4 border-t border-gray-200 bg-white p-4 text-lg">
        <button
          id="button-close-mobile-search"
          className="rounded-lg border border-gray-200 px-4 py-3 text-center text-base text-gray-500  duration-300 active:scale-95"
          onClick={() => {
            onClose();
          }}
        >
          {t('cancelBtn')}
        </button>
        <button
          id="button-next-mobile-search"
          disabled={!checkIn}
          className={`${
            checkIn ? 'bg-primary' : 'bg-gray-200'
          } rounded-lg px-4 py-3 flex items-center justify-center text-base text-white duration-300 active:scale-95`}
          onClick={handleOnNext}
        >
          <SearchIcon className="mr-1 h-4" />

          {t('mobileBtn')}
        </button>
      </div>
    </>
  );
};

const GuestPicker = ({ handleOnNext, guests, setterGuests, onClose }) => {
  const t = useTranslations('Header.search');
  const { guestAdults, guestChildren, guestInfants, guestBikes } = guests as IGuestCount;
  const { setGuestAdults, setGuestChildren, setGuestInfants, setGuestBikes } =
    setterGuests;
  return (
    <>
      <div>
        <div className="flex border-b border-gray-200 border-opacity-70 py-4">
          <div className="flex-grow">
            <h2 className="font-medium">Adults</h2>
            <p className="text-sm leading-4 text-gray-300">Ages 13 or above</p>
          </div>
          <AppCounter value={guestAdults} setValue={setGuestAdults} maxValue={16} />
        </div>
      </div>
      <div>
        <div className="flex border-b border-gray-200 border-opacity-70 py-4">
          <div className="flex-grow">
            <h2 className="font-medium">Children</h2>
            <p className="text-sm leading-4 text-gray-300">Ages 2-12</p>
          </div>
          <AppCounter value={guestChildren} setValue={setGuestChildren} maxValue={5} />
        </div>
      </div>
      <div>
        <div className="flex py-4">
          <div className="flex-grow">
            <h2 className="font-medium">Infants</h2>
            <p className="text-sm leading-4 text-gray-300">Under 2</p>
          </div>
          <AppCounter value={guestInfants!} setValue={setGuestInfants} maxValue={5} />
        </div>
      </div>
      <div>
        <div className="flex py-4">
          <div className="flex-grow">
            <h2 className="font-medium">Bikes</h2>
            <p className="text-sm leading-4 text-gray-300">Mounted</p>
          </div>
          <AppCounter
            value={guestBikes}
            setValue={setGuestBikes}
            maxValue={2 * guestAdults}
          />
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 grid grid-cols-2 items-center gap-x-4 border-t border-gray-200 bg-white p-4 text-lg">
        {Object.values(guests).reduce((acc: number, curr: number) => acc + curr) ? (
          <span
            className="rounded-lg border border-gray-200 px-4 py-3 text-center text-base text-gray-500 duration-300 active:scale-95"
            onClick={() => {
              onClose();
            }}
          >
            {t('cancelBtn')}
          </span>
        ) : (
          <span></span>
        )}
        <button
          id="button-gtm-search"
          className="flex items-center justify-center rounded-lg bg-primary px-4 py-3 text-base text-white duration-300 active:scale-95"
          onClick={handleOnNext}
        >
          <SearchIcon className="mr-1 h-4" />
          {t('mobileBtn')}
        </button>
      </div>
    </>
  );
};

export default AppSearchOptionMobile;
