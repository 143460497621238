import { DateTime, Duration } from 'luxon';

export const getDiffHoursFromDates = (arrival: string, departure: string) => {
  const diffDuration: DateTime.diff = DateTime.fromISO(arrival).diff(
    DateTime.fromISO(departure),
    ['hours', 'days', 'minutes']
  );
  if (diffDuration.days > 1) {
    return diffDuration.toFormat("d'd 'h'h'mm");
  } else if (
    diffDuration.hours > 0 ||
    (diffDuration.hours === 0 && diffDuration.days > 0)
  ) {
    return diffDuration.toFormat("h'h'mm");
  } else {
    return diffDuration.toFormat("m'min");
  }
};

export const getDiffInHours = (arrival: string, departure: string): number => {
  const diffDuration: DateTime.diff = DateTime.fromISO(arrival).diff(
    DateTime.fromISO(departure),
    ['hours', 'minutes']
  );
  return diffDuration.hours;
};

export const getHoursFromDuration = (duration: Duration) => {
  if (duration.hours > 0) {
    return `${duration.hours}h${duration.minutes}`;
  } else {
    return `${duration.minutes}`;
  }
};

export const getFormattedDurationFromSeconds = (seconds: number) => {
  const duration = Duration.fromObject({ seconds });
  if (duration.as('days') > 1) {
    return duration.toFormat("d'd'h'h'mm");
  } else if (duration.as('hours') > 0) {
    return duration.toFormat("h'h'mm");
  } else {
    return duration.toFormat('m');
  }
};

export const formatCheckDate = (
  date: Date,
  locale?: string,
  dateFormat: DateTime.Format = { month: 'short', day: 'numeric' }
) => {
  const dateObj = new Date(date);
  if (!locale) {
    //format to month and day using luxon
    return DateTime.fromISO(dateObj.toISOString()).toFormat('LLL dd');
  }
  const formattedDate = dateObj.toLocaleDateString(locale, dateFormat);
  return formattedDate;
};

export const formatRangeDate = (start: Date, end: Date) => {
  const startDate = new DateTime(start);
  const endDate = new DateTime(end);

  if (!startDate || !endDate) {
    return false;
  }
  let template = `${formatCheckDate(startDate)} - ${formatCheckDate(endDate)}`;
  if (startDate.toFormat('d m y') === endDate.toFormat('d m y')) {
    template = `${formatCheckDate(startDate)} - ${parseInt(endDate.toFormat('d')) + 1}`;
  }

  if (startDate.toFormat('y') !== endDate.toFormat('y')) {
    template = `${startDate.toFormat('MMM d, y')} - ${endDate.toFormat('MMM d, y')}`;
  }
  return template;
};

export const setToBrowserTz = (time) => {
  return time.setZone(DateTime.local().zoneName);
};

export const formatLinkDate = (date, locale) => {
  return DateTime.fromISO(date, { zone: 'Europe/Paris' })
    .setLocale(locale)
    .toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const gapToDepartureDay = (departureTime: any, travelDays: any) => {
  const nextDay = new Date(departureTime);
  nextDay.setDate(nextDay.getDate() + travelDays);
  return nextDay;
};

export const computeInterrailTravelDays = (legs) => {
  if (!legs || legs.length === 0) {
    return 0;
  }
  const days = legs.map((leg) => DateTime.fromISO(leg.departure).toFormat('yyyy-MM-dd'));
  //add legs arrival dates to the days array

  return new Set(days).size;
};

export const isNextDay = (departure: DateTime, arrival: DateTime) => {
  return !DateTime.fromISO(arrival, { zone: 'Europe/Paris' }).hasSame(
    DateTime.fromISO(departure, { zone: 'Europe/Paris' }),
    'day'
  );
};
