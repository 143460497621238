'use client';

import { useState } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import { useTranslations } from 'next-intl';

import AppNearby from '@/components/layout/AppNearby';
import AppSearchOptionMobile from '@/components/search/AppSearchOptionMobile';
import {
  useCheckIn,
  useOriginName,
  useGuestAdults,
  useGuestChildren,
  useGuestInfants,
  useGuestSeniors,
  useGuestYouths,
  useDestinationName,
} from 'hooks/useQuerySearch';
import { EHeaderOptions, IExploreNearby } from 'typings';
import { utf8_to_latin1 } from 'utils/stationsUtils';

import AppAutocomplete from '../layout/AppAutocomplete';
import { CalendarIcon } from 'lucide-react';
import AppNearbyList from '../layout/AppNearbyList';
import { top5DestinationsFrom } from 'config/topOriginStations';

interface IAppSearchBarMobileProps {
  exploreNearby: IExploreNearby[];
  searchPage?: boolean;
  activeMenu?: EHeaderOptions;
}

const AppSearchBarMobile = ({
  exploreNearby,
  searchPage,
  activeMenu,
}: IAppSearchBarMobileProps) => {
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<'origin' | 'destination'>('origin');
  const [activeStep, setActiveStep] = useState<boolean>(false);

  const [originName] = useOriginName();
  const [destinationName] = useDestinationName();
  const [typedOrigin, setTypedOrigin] = useState<string>(originName);
  const [typedDestination, setTypedDestination] = useState<string>(destinationName);

  const [guestAdults] = useGuestAdults();
  const [guestYouths] = useGuestYouths();
  const [guestChildren] = useGuestChildren();
  const [guestInfants] = useGuestInfants();
  const [guestSeniors] = useGuestSeniors();

  const t = useTranslations('Header.search');

  const openKeyboard = () => {
    const input = document.getElementById('search-input') as HTMLInputElement;
    input?.focus();
    input.select();
  };

  return (
    <>
      {/* mobile search button */}
      {searchPage && (
        <div className="container block w-full md:hidden px-4 md:px-8 mt-2">
          <div className="relative flex h-12 items-center justify-center rounded-full border bg-gray-50">
            {searchPage ? (
              <div className="flex w-full flex-row items-center justify-start pl-2">
                <button
                  id="search-loop-mobile"
                  className="rounded-full bg-white p-2 duration-300 active:scale-90"
                  onClick={() => {
                    setActiveSearch(true);
                    openKeyboard();
                  }}
                >
                  <SearchIcon className="h-5" />
                </button>
                <button
                  id="search-origin-mobile"
                  className="flex w-1/2 flex-grow flex-col overflow-hidden border-r border-gray-200 px-3 pl-2"
                  onClick={() => {
                    setActiveSearch(true);
                    setSearchType('origin');
                    openKeyboard();
                  }}
                >
                  <div className="flex">
                    <div className="min-w-10 -mb-0.5 flex">
                      <span className="text-xs text-gray-300">{t('from.title')}</span>
                    </div>
                  </div>
                  <div className="flex" aria-hidden="true">
                    <div>
                      <span className="whitespace-nowrap font-semibold">
                        {utf8_to_latin1(originName) || t('from.default')}
                      </span>
                    </div>
                  </div>
                </button>
                <button
                  id="search-destination-mobile"
                  className="flex w-1/2 flex-grow flex-col overflow-hidden border-r border-gray-200 px-3"
                  onClick={() => {
                    setActiveSearch(true);
                    setSearchType('destination');
                    openKeyboard();
                  }}
                >
                  <div className="flex">
                    <div className="min-w-10 -mb-0.5 flex">
                      <span className="text-xs text-gray-300">{t('to.title')}</span>
                    </div>
                  </div>
                  <div className="flex" aria-hidden="true">
                    <div>
                      <span className="whitespace-nowrap font-semibold">
                        {utf8_to_latin1(destinationName) || t('to.default')}
                      </span>
                    </div>
                  </div>
                </button>
                {activeMenu === EHeaderOptions.STATION_TO_STATION ? (
                  <button
                    id="search-bar-checkin-mobile"
                    onClick={() => setActiveStep(true)}
                    className="flex flex-col px-3 mx-1 bg-white h-10 w-10 justify-center items-center rounded-full"
                  >
                    <CalendarIcon className="h-5 w-5 text-primary" />
                  </button>
                ) : (
                  <button
                    id="search-bar-guests-mobile"
                    onClick={() => setActiveStep(true)}
                    className="min-w-20 flex w-1/2 flex-col px-4 pl-3"
                  >
                    <div className="flex">
                      <div className="min-w-10 -mb-0.5 flex">
                        <span className="text-xs text-gray-300">
                          {t('travelers.title', { count: 2 })}
                        </span>
                      </div>
                    </div>
                    <div className="flex" aria-hidden="true">
                      <div>
                        <span className="font-semibold">
                          {guestAdults +
                            guestChildren +
                            guestYouths +
                            guestSeniors +
                            guestInfants || t('guests.default')}
                        </span>
                      </div>
                    </div>
                  </button>
                )}
              </div>
            ) : (
              <button
                id="search-bar-placeholder-mobile"
                className="mx-11 flex h-full w-full items-center justify-center"
                onClick={() => setActiveSearch(true)}
              >
                <SearchIcon className="mr-1 h-5 text-primary" />
                <span className="text-sm font-medium tracking-wide">
                  {t('mobileplaceholder')}
                </span>
              </button>
            )}
          </div>
        </div>
      )}
      {/* mobile search section */}

      <section
        id="search-section-mobile"
        aria-label="search-section"
        className={`${!activeSearch && 'top-full'} fixed inset-x-0 top-0 bottom-0 z-50 flex w-full flex-col justify-between bg-gradient-to-r from-orange to-orange duration-500 ease-out`}
      >
        <div
          className={`t1ie relative grid flex-shrink-0 items-center justify-items-center pt-[18px] pb-[10px] `}
          id={`${searchType === 'origin' ? 'origin-search' : 'destination-search'}`}
        >
          <div className="cgg0kx3 absolute left-5 top-[22px] block">
            <button
              id="close-search-mobile"
              aria-label="Close search"
              type="button"
              className="_1wn6bem border-gray-250 inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-[1px] bg-white outline-none"
            >
              <span onClick={() => setActiveSearch(false)} className="_3hmsj">
                <svg
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="presentation"
                  focusable="false"
                  className="block h-3 w-3 overflow-visible stroke-current stroke-[4px]"
                >
                  <g fill="none">
                    <path d="m4 16h26"></path>
                    <path d="m15 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932"></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div>
            <div data-testid="tab-list-wrapper">
              <div role="tablist" className="block pt-1">
                <button
                  type="button"
                  id={`tab--vertical-tabs--${searchType === 'origin' ? '0' : '1'}`}
                  aria-controls={`panel--vertical-tabs--${searchType === 'origin' ? '0' : '1'}`}
                  aria-selected="true"
                  className="_ys1ahke inline-block items-center text-center text-2xl font-medium text-white"
                  role="tab"
                >
                  {searchType === 'origin'
                    ? t('from.explicitTitle')
                    : t('to.explicitTitle')}
                </button>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <div className="t1gnb3ax block flex-grow overflow-y-auto overflow-x-hidden">
          <div
            role="tabpanel"
            id={`panel--vertical-tabs--${searchType === 'origin' ? '0' : '1'}`}
            aria-labelledby={`tab--vertical-tabs--${searchType === 'origin' ? '0' : '1'}`}
            className="block"
          >
            <div className="pgr8wsf mx-3 mt-4 block">
              <div className="pnry4ot block pb-3">
                <div className="p10ugdmb top-20 contents">
                  <div
                    className={`p1uyj2jw absolute top-[80px] left-0 right-0 bottom-0 z-20 m-0 rounded-t-3xl border-none bg-white shadow-arround`}
                  >
                    <div
                      className="biod118 block h-full p-6"
                      aria-hidden="false"
                      id={`accordion-body-/homes-${searchType === 'origin' ? '1' : '2'}`}
                    >
                      <div className="b1dsjvyj block ">
                        <div className="c1guygdh flex items-center">
                          <div className="iv6icr7 w-full">
                            <div>
                              <form
                                id={`form-mobile-search-${searchType}`}
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  setActiveSearch(false);
                                  setTimeout(() => {
                                    setActiveStep(true);
                                  }, 200);
                                }}
                                role="search"
                                className="f1w3ezqn flex-1"
                                action="/s/homes"
                                method="get"
                                data-form-type=""
                              >
                                <div dir="ltr">
                                  <div className="_1258d0t relative h-full w-full">
                                    <label
                                      htmlFor={`origin-input-${searchType}`}
                                      className="_my7uk9q relative flex h-[60px] flex-1 items-center overflow-hidden whitespace-nowrap rounded-xl bg-gray-100 px-5 text-gray-500"
                                      data-dashlane-label="true"
                                    >
                                      <div className="_vjvlr2 mr-2 flex h-full items-center text-gray-500">
                                        <svg
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-hidden="true"
                                          role="presentation"
                                          focusable="false"
                                          className="block h-4 w-4 overflow-visible stroke-current stroke-[4px]"
                                        >
                                          <g fill="none">
                                            <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path>
                                          </g>
                                        </svg>
                                      </div>
                                      <input
                                        className="_1lcbjssi h-full w-full overflow-ellipsis border-none bg-transparent pr-9 text-sm font-normal text-gray-500 outline-none"
                                        onChange={({ target }) => {
                                          searchType === 'origin'
                                            ? setTypedOrigin(target.value)
                                            : setTypedDestination(target.value);
                                        }}
                                        data-testid={`search_query_input-${searchType}`}
                                        autoComplete="off"
                                        id={`search-input`}
                                        placeholder={`Your ${searchType} city or station`}
                                        aria-autocomplete="list"
                                        autoCorrect="off"
                                        spellCheck="false"
                                        name="query"
                                        aria-label={`Your ${searchType} city or station`}
                                        value={
                                          searchType === 'origin'
                                            ? typedOrigin
                                            : typedDestination
                                        }
                                        role="combobox"
                                        aria-controls={`origin-input-${searchType}`}
                                        aria-expanded="true"
                                        aria-haspopup="listbox"
                                        data-form-type=""
                                      />
                                    </label>
                                    <div
                                      data-testid={`search_clearButton-${searchType}`}
                                      className="_mqu3ht absolute right-6 top-0 bottom-0 flex h-full items-center"
                                    >
                                      <button
                                        id={`button-clear-search-input-mobile-${searchType}`}
                                        onClick={() => {
                                          searchType === 'origin'
                                            ? setTypedOrigin('')
                                            : setTypedDestination('');
                                        }}
                                        aria-label="Effacer la saisie"
                                        type="button"
                                        className={`_1c61vvr ${
                                          typedOrigin ? 'opacity-100' : 'opacity-0'
                                        } touch bg-gray-250 relative inline-block cursor-pointer appearance-none rounded-3xl border-none bg-transparent p-1 text-gray-500 outline-none`}
                                        data-dashlane-label="true"
                                        data-form-type=""
                                      >
                                        <span className="_e296pg relative">
                                          <svg
                                            className="block h-3 w-3 overflow-visible stroke-current stroke-[5px]"
                                            viewBox="0 0 32 32"
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="presentation"
                                            focusable="false"
                                          >
                                            <path d="m6 6 20 20"></path>
                                            <path d="m26 6-20 20"></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        {/* Suggested Stations */}

                        <div
                          role="listbox"
                          aria-label={`Suggestions de recherche pour ${searchType}`}
                          id={`/homes-${searchType === 'origin' ? '1' : '2'}-listbox`}
                          className="r1bar4jl r9vbvmc dir dir-ltr absolute left-0 right-0 mt-4 h-[632px] overflow-auto bg-white"
                        >
                          {searchType === 'origin' && typedOrigin ? (
                            <AppAutocomplete
                              locationName={typedOrigin}
                              changeOrigin={true}
                              onClick={() => {
                                setActiveSearch(false);
                                setTimeout(() => {
                                  setActiveStep(true);
                                }, 200);
                              }}
                            />
                          ) : searchType === 'destination' && typedDestination ? (
                            <AppAutocomplete
                              locationName={typedDestination}
                              changeOrigin={false}
                              onClick={() => {
                                setActiveSearch(false);
                                setTimeout(() => {
                                  setActiveStep(true);
                                }, 200);
                              }}
                            />
                          ) : (
                            <AppNearbyList
                              exploreNearby={exploreNearby}
                              numberOfItems={5}
                              include={top5DestinationsFrom(originName)}
                              changeOrigin={searchType === 'origin'}
                              exclude={searchType === 'destination' ? originName : ''}
                              onClick={() => {
                                setActiveSearch(false);
                                setTimeout(() => {
                                  setActiveStep(true);
                                }, 200);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AppSearchOptionMobile
        active={activeStep}
        onClose={() => {
          setActiveSearch(false);
          setActiveStep(false);
        }}
      />
      {/* bottom navigation 
        <AppMobileNavigation />
        */}
    </>
  );
};

export default AppSearchBarMobile;
