import { ParserBuilder, UseQueryStateReturn, useQueryState } from 'nuqs';

import { searchParamsParsers } from './querySearch';

function buildQueryHook<T>(parameterName: string) {
  //We need a level of indirection to ensure hooks are executed in the right context
  return () =>
    useQueryState(
      parameterName,
      searchParamsParsers[parameterName] as ParserBuilder<T>
    ) as UseQueryStateReturn<T, T>;
}

//#region Basic journey information
export function useOriginName() {
  return buildQueryHook<string>('origin')();
}

export function useDestinationName() {
  return buildQueryHook<string>('destination')();
}

export function useCheckIn() {
  return buildQueryHook<Date>('checkIn')();
}

export function useCheckOut() {
  return buildQueryHook<Date>('checkOut')();
}
//#endregion

//#region Guest
export function useGuestAdults() {
  return buildQueryHook<number>('guestAdults')();
}
export function useGuestYouths() {
  return buildQueryHook<number>('guestYouths')();
}

export function useGuestChildren() {
  return buildQueryHook<number>('guestChildren')();
}

export function useGuestInfants() {
  return buildQueryHook<number>('guestInfants')();
}

export function useGuestSeniors() {
  return buildQueryHook<number>('guestSeniors')();
}

export function useGuestBikes() {
  return buildQueryHook<number>('guestBikes')();
}
//#endregion

export function useNightTrainMode() {
  return buildQueryHook<boolean>('nightTrain')();
}

export function useSkiMode() {
  return buildQueryHook<boolean>('skiMode')();
}

export function usePassType() {
  return buildQueryHook<string>('passType')();
}

export function usePassClass() {
  return buildQueryHook<number>('passClass')();
}

export function usePassTravelDays() {
  return buildQueryHook<string>('passTravelDays')();
}

export function useTrip() {
  return buildQueryHook<string[]>('trip')();
}

export function useSelectedOutbound() {
  return buildQueryHook<string>('selectedOutbound')();
}
export function useSelectedReturn() {
  return buildQueryHook<string>('selectedReturn')();
}
