import { Leg } from './journey';

export const CompaniesImages = (name: string) => {
  if (name) {
    switch (true) {
      case name === 'db':
        return '/assets/logos/db_trainsv2.png';
      case name === 'flix':
      case name === 'STARSHIPPER SAS':
      case name.startsWith('FlixBus'):
        return '/assets/logos/flix_bus.png';
      case name === 'Österreichische Bundesbahnen':
      case name === 'obb':
        return '/assets/logos/cityjet_train.png';
      case name === 'eurolines':
        return '/assets/logos/eurolines_bus.jpg';
      case name === 'ecolines':
        return '/assets/logos/ecolines_bus.png';
      case name === 'comboios':
        return '/assets/icons/comboios.svg';
      case name === 'elron':
        return '/assets/logos/elron_train.png';
      case name === 'lux':
        return '/assets/logos/lux_bus.png';
      case name === 'blablabus':
        return '/assets/logos/blabla_bus.png';
      case name === 'BlaBlaCar':
        return '/assets/logos/blabla.svg';
      case name === 'flixtrain':
        return '/assets/logos/flix_train.png';
      case name === 'mav':
        return '/assets/logos/railjet_train.png';
      case name === 'train':
      case name === 'google':
        return '/assets/logos/train.png';
      case name === 'sncf':
        return '/assets/logos/sncf_train.png';
      case name === 'FlixTrain':
      case name == 'FlixTrain GmbH':
      case name.startsWith('FlixTrain'):
        return '/assets/logos/flix_train.png';
      case name === 'WESTbahn':
        return '/assets/logos/ICE.png';
      case name === 'ITALO TRENO':
        return '/assets/logos/italo.png';
      case name === 'GWR':
        return '/assets/logos/gwr.png';
      case name === 'Caledonian Sleeper':
        return '/assets/logos/caledonian-sleeper.png';
      case name === 'SJ Industries':
        return '/assets/logos/sj_intercity';
      case name === 'Eurostar':
        return '/assets/logos/eurostar.png';
      case name === 'Lumo':
        return '/assets/logos/lumo.png';
      case name === 'ScotRail':
        return '/assets/logos/scotrail.png';
    }
  }
};

export const LineImagebyName = {
  Default: '/assets/logos/train_shadow.svg',
  'TGV INOUI': '/assets/logos/TGV INOUI.png',
  Thalys: '/assets/logos/Thalys.png',
  ICE: '/assets/logos/ICE.png',
  OUIGO: '/assets/logos/OUIGO.png',
  'DB SNCF': '/assets/logos/TGV INOUI.png',
  'TGV Lyria': '/assets/logos/Lyria.png',
  Lyria: '/assets/logos/Lyria.png',
  RER: '/assets/logos/RER.png',
  TER: '/assets/logos/RER.png',
  'Regional TER': '/assets/logos/RER.png',
  RJX: '/assets/logos/railjet_train.png',
  RJ: '/assets/logos/railjet_train.png',
  'Rail Jet': '/assets/logos/railjet_train.png',
  Railjet: '/assets/logos/railjet_train.png',
  IC: '/assets/logos/IC.png',
  ICR: '/assets/logos/IC.png',
  ICL: '/assets/logos/IC.png',
  EC: '/assets/logos/ICE 504.png',
  EuroCity: '/assets/logos/ICE 504.png',
  FlixBus: '/assets/logos/flix_bus.png',
  FlixTrain: '/assets/logos/flix_train.png',
  FLX35: '/assets/logos/flix_train.png',
  FLX30: '/assets/logos/flix_train.png',
  FLX20: '/assets/logos/flix_train.png',
  FLX10: '/assets/logos/flix_train.png',
  FLX61: '/assets/logos/flix_train.png',
  TGV: '/assets/logos/sncf_train.png',
  'IRN 347': '/assets/logos/cfr-mav-night.png',
  Nightjet: '/assets/logos/nightjet_train.png',
  'Nightjet NG': '/assets/logos/nightjet_train.png',
  'Night Jet': '/assets/logos/nightjet_train.png',
  'Night Train': '/assets/logos/ICN.png',
  EuroNight: '/assets/logos/nightjet_train.png',
  EN: '/assets/logos/nightjet_train.png',
  'EN 345': '/assets/logos/sj_intercity.png',
  'EN 346': '/assets/logos/sj_intercity.png',
  'EN 40237': '/assets/logos/cfr-mav-night.png',
  'EN 40467': '/assets/logos/nightjet_train.png',
  'EN 50237': '/assets/logos/cfr-mav-night.png',
  'RE ': '/assets/logos/DBRegio_train.png',
  RB64: '/assets/logos/DBRegio_train.png',
  'DB Regio AG NRW': '/assets/logos/DBRegio_train.png',
  'DB Regio AG Mitte': '/assets/logos/DBRegio_train.png',
  'DB Regio AG': '/assets/logos/DBRegio_train.png',
  'Regional-Express': '/assets/logos/DBRegio_train.png',
  Snälltåget: '/assets/logos/snalltaget.png',
  '10300': '/assets/logos/snalltaget.png',
  '1 ': '/assets/logos/tram.png',
  FR: '/assets/logos/Frecciarossa_train.png',
  Frecciargento: '/assets/logos/frecciargento.png',
  B: '/assets/logos/RER.png',
  A: '/assets/logos/RER.png',
  'Zrt. S50': '/assets/logos/mav_train.png',
  'R ': '/assets/logos/railjet_train.png',
  REX: '/assets/logos/cityjet_train.png',
  REG: '/assets/logos/Polregio.png',
  'BlaBlaCar Bus': '/assets/logos/blabla_bus.png',
  ALSA: '/assets/logos/alsa_bus.png',
  Orlyval: '/assets/logos/tram.png',
  INTERCITES: '/assets/logos/TRANSILIEN.png',
  Intercités: '/assets/logos/TRANSILIEN.png',
  'Intercités de nuit': '/assets/logos/ICN.png',
  'OUIGO Train Classique': '/assets/logos/OUIGO_TC.png',
  TRANSILIEN: '/assets/logos/TRANSILIEN.png',
  Transilien: '/assets/logos/TRANSILIEN.png',
  RHI: '/assets/logos/sncf_train.png',
  THA: '/assets/logos/Thalys.png',
  RHT: '/assets/logos/sncf_train.png',
  NJ: '/assets/logos/nightjet_train.png',
  IR: '/assets/logos/sbb.png',
  DPN: '/assets/logos/RER.png',
  IXB: '/assets/logos/ICE 504.png',
  TGJ: '/assets/logos/sncf_train.png',
  CarTreize: '/assets/logos/alsa_bus.png',
  Eurostar: '/assets/logos/eurostar.png',
  'Union Ivkoni': '/assets/logos/unionivkoni.png',
  Iryo: '/assets/logos/Frecciarossa_train.png',
  'Iryo 06400': '/assets/logos/Frecciarossa_train.png',
  'Iryo 06200': '/assets/logos/Frecciarossa_train.png',
  'Iryo 06100': '/assets/logos/Frecciarossa_train.png',
  'Iryo 06300': '/assets/logos/Frecciarossa_train.png',
  AVE: '/assets/logos/AVE.png',
  AVLO: '/assets/logos/AVLO.png',
  'Eu Sleeper': '/assets/logos/EuropeanSleeper.png',
  'European Sleeper': '/assets/logos/EuropeanSleeper.png',
  ES452: '/assets/logos/EuropeanSleeper.png',
  ES453: '/assets/logos/EuropeanSleeper.png',
  'NS Groep N.V.': '/assets/logos/NS_intercity.png',
  'Intercity Direct': '/assets/logos/NS_intercity.png',
  'Intercity direct': '/assets/logos/NS_intercity.png',
  'Stoptrein RS11': '/assets/logos/arriva_nl.png',
  'Stoptrein RE 19': '/assets/logos/stoptrein.png',
  Sprinter: '/assets/logos/NS_sprinter.png',
  Eurobahn: '/assets/logos/NS_sprinter.png',
  'National Express': '/assets/logos/national_express.png',
  VIAS: '/assets/logos/stoptrein.png',
  S1: '/assets/logos/sncb.png',
  S2: '/assets/logos/sncb.png',
  S3: '/assets/logos/sncb.png',
  S4: '/assets/logos/sncb.png',
  S5: '/assets/logos/sncb.png',
  S6: '/assets/logos/sncb.png',
  S7: '/assets/logos/sncb.png',
  S11: '/assets/logos/trenord.png',
  RE4: '/assets/logos/trenord.png',
  ICN: '/assets/logos/InterciteNotte.png',
  InterCityNotte: '/assets/logos/InterciteNotte.png',
  'InterCity Notte': '/assets/logos/InterciteNotte.png',
  'ITALO TRENO': '/assets/logos/italo.png',
  ALVIA: '/assets/logos/ALVIA.png',
  Alvia: '/assets/logos/ALVIA.png',
  AVANT: '/assets/logos/ALVIA.png',
  MD: '/assets/logos/renfe_MD.png',
  TRN: '/assets/logos/renfe_MD.png',
  Euromed: '/assets/logos/ALVIA.png',
  Cercanías: '/assets/logos/renfe_MD.png',
  'Cercanías C1': '/assets/logos/renfe_MD.png',
  'Cercanías C2': '/assets/logos/renfe_MD.png',
  'Cercanías C3': '/assets/logos/renfe_MD.png',
  'Cercanías C4': '/assets/logos/renfe_MD.png',
  'OUIGO ESPAÑA': '/assets/logos/OUIGO_ESP.png',
  'OUIGO Grande Vitesse': '/assets/logos/OUIGO.png',
  'SJ Snabbtåg SJ3000': '/assets/logos/sj_3000.png',
  'SJ InterCity': '/assets/logos/sj_intercity.png',
  'SJ Snabbtåg SkåneExpressen': '/assets/logos/sj_x12.png',
  'SJ Regionaltåg': '/assets/logos/sj_doubledecker.png',
  '13402': '/assets/logos/sj_3000.png',
  '346': '/assets/logos/sj_intercity.png',
  'Trenitalia REG': '/assets/logos/trenitalia_reg.png',
  RV: '/assets/logos/trenitalia_rv.png',
  'Midnight Trains': '/assets/logos/midnighttrain.png',
  'Night Riviera': '/assets/logos/gwr.png',
  GWR: '/assets/logos/gwr.png',
  HighLander: '/assets/logos/caledonian-sleeper.png',
  'Caledonian Sleeper': '/assets/logos/caledonian-sleeper.png',
  Lowlander: '/assets/logos/caledonian-sleeper.png',
  'TransPennine Express': '/assets/logos/transpennine.png',
  'Avanti West Coast': '/assets/logos/avanti.png',
  Thameslink: '/assets/logos/thameslink.png',
  LNER: '/assets/logos/lner.png',
  CrossCountry: '/assets/logos/crosscountry.png',
  ScotRail: '/assets/logos/scotrail.png',
  Lumo: '/assets/logos/Lumo.png',
};

const isTGVINOUI = (reference: string) => {
  //if reference is a number between 6000 and 9999 return true
  return (
    (parseInt(reference) >= 6000 && parseInt(reference) <= 9999) ||
    (parseInt(reference) >= 12200 && parseInt(reference) <= 12299)
  );
};
const isTGVLyria = (reference: string) => {
  //if reference is a number between 9758 and (9789) or 9260 and 9277 or 9203 and 9230 return true
  return (
    (parseInt(reference) >= 9758 && parseInt(reference) <= 9789) ||
    (parseInt(reference) >= 9260 && parseInt(reference) <= 9277) ||
    (parseInt(reference) >= 9203 && parseInt(reference) <= 9234)
  );
};

const ImagebyNameAndRef = (name: string, reference: string) => {
  if (name === 'Intercités' && reference === '64003') {
    return '/assets/logos/blabla_bus.png';
  } else if (name === 'Intercités' && reference === '64001') {
    return '/assets/logos/blabla_bus.png';
  } else if (name === 'Intercités' && reference === '64221') {
    return '/assets/logos/blabla_bus.png';
  } else if (name === 'SNCF' && isTGVLyria(reference)) {
    return '/assets/logos/Lyria.png';
  } else if (name === 'SNCF' && isTGVINOUI(reference)) {
    return '/assets/logos/TGV INOUI.png';
  } else if (name === 'SNCF 12261') {
    return '/assets/logos/TGV INOUI.png';
  } else if (name === 'SNCF 12259' && reference === '12259') {
    return '/assets/logos/TGV INOUI.png';
  } else {
    return null;
  }
};

export const LineImages = (leg: Leg) => {
  const legName = leg.line?.name;
  if (leg.line?.reference) {
    const imgByRef = ImagebyNameAndRef(legName, leg.line.reference);
    if (imgByRef) {
      return imgByRef;
    }
  }

  const imgByName =
    LineImagebyName[legName] ||
    LineImagebyName[legName?.substring(0, 3)] ||
    LineImagebyName[legName?.substring(0, 2)] ||
    null;
  const imgByCompany = CompaniesImages(leg.operator?.name) || null;

  return imgByName || imgByCompany || '/assets/logos/train.png';
};
